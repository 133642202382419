import React, {useEffect, useRef, useState} from 'react'
import Sun from '../assets/sun.svg'
import Moon from '../assets/moon.svg'

const ThemeToggle = () => {
    const [theme, setTheme] = useState(localStorage.getItem('theme'))

    if (!theme) {
        localStorage.setItem('theme', 'light')
        document.documentElement.setAttribute('data-theme', 'light')
        document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#fff")
        setTheme('light')
    }
    const toggleRef = useRef()
    const [clicks, setClicks] = useState(1)

    const changeTheme = (e) => {
        e.preventDefault()
        toggleRef.current.style.transition = '.2s'
        toggleRef.current.style.rotate = `${clicks * 360}deg`

        if (theme === 'light') {
            localStorage.setItem('theme', 'dark')
            document.documentElement.setAttribute('data-theme', 'dark')
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#1e1e1e")
            setTheme('dark')
        }

        if (theme === 'dark') {
            localStorage.setItem('theme', 'light')
            document.documentElement.setAttribute('data-theme', 'light')
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#fff")
            setTheme('light')
        }
        setClicks(clicks + 1)
    }

    useEffect(() => {
        if (theme === 'light') {
            document.documentElement.setAttribute('data-theme', 'light')
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#fff")


        }

        if (theme === 'dark') {
            document.documentElement.setAttribute('data-theme', 'dark')
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#1e1e1e")


        }
    }, [])


    return (
        <div onClick={changeTheme} ref={toggleRef}
             style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '18px', height: '18px', marginTop: '-2px'}}>
            {theme === 'light' ?
                <img src={Sun} alt="light"
                     className={'sun'}
                /> :
                <img src={Moon} alt="dark"
                     className={'sun'}/>

            }
        </div>
    )
}

export default ThemeToggle