import React from 'react';
import Row from '../layout/Row';
import Cell from '../layout/Cell';
import MuseumBanner from './MuseumBanner';
import './Museum.css';


const Museum = (props) => {
    const galleries = props.galleries;
    return (
        <section className="museum-container">
            <h2 className='-center'
                dangerouslySetInnerHTML={{__html: props.title}}
            />
            <Row className="museum-row">
                {galleries[0] && <Cell className = "-half museum-gallery-container">
                    <MuseumBanner
                        thumbnail={galleries[0].thumbnail} 
                        gallery={galleries[0].gallery}
                        key="gallery0"
                    />
                    <p className='-margin-16'>
                        {galleries[0].description}
                    </p>
                    <p className='museum-exhibits'>
                        {galleries[0].exhibits}
                    </p>
                </Cell>}
                {galleries[1] && <Cell className = "-half museum-gallery-container museum-cell">
                    <MuseumBanner
                        thumbnail={galleries[1].thumbnail}  
                        gallery={galleries[1].gallery}
                        key="gallery1"
                    />
                    <p className='-margin-16'>
                        {galleries[1].description}
                    </p>
                    <p className='museum-exhibits'>
                        {galleries[1].exhibits}
                    </p>
                </Cell>}
            </Row>
            <Row className="museum-row">
                {galleries[3] && <Cell className = "-half museum-gallery-container">
                    <MuseumBanner
                        thumbnail={galleries[3].thumbnail}  
                        gallery={galleries[3].gallery}
                        key="gallery2"
                    />
                    <p className='-margin-16'>
                        {galleries[3].description}
                    </p>
                    <p className='museum-exhibits'>
                        {galleries[3].exhibits}
                    </p>
                </Cell>}
                {galleries[2] && <Cell className = "-half museum-gallery-container museum-cell">
                    <MuseumBanner
                        thumbnail={galleries[2].thumbnail}  
                        gallery={galleries[2].gallery}
                        key="gallery3"
                    />
                    <p className='-margin-16'>
                        {galleries[2].description}
                    </p>
                    <p className='museum-exhibits'>
                        {galleries[2].exhibits}
                    </p>
                </Cell>}
            </Row>
        </section>
    );
};

export default Museum;