import React, {useState, useEffect} from "react"
import Content from "../core/Content";
import Welcome from "../components/Welcome";
import BigBlock from "../components/BigBlock";
import Blocks from "../components/Blocks";
import LastBlock from "../components/LastBlock";
import welcomeData from "../files/text/welcome.json";
import SumAnimation from '../files/features/SumOfTechnologies.json'
import SumAnimationMobile from '../files/features/SumOfTechnologiesMobile.json'
import HomePageBlogs from "../components/HomePageBlogs";

const Home = () => {
    const [welcomeBlock, setWelcomeBlock] = useState(false)
    const [firstBlock, setFirstBlock] = useState(false)
    const [blogs, setBlogs] = useState(false)
    const [blocks, setBlocks] = useState(false)
    const [lastBlock, setLastBlock] = useState(false)
    const content = new Content()
    const defaultTitle = content.defaultTitle

    useEffect(() => {
        const welcome = new Content();
          

        (async () => {

            let welcomeJson = welcomeData[localStorage.getItem('SELECTED_LANGUAGE')] || welcomeData['en']

            setWelcomeBlock(welcomeJson.welcome)


            welcomeJson.firstBlock.welcomeAnimation = welcome.isMobile
                ? SumAnimationMobile
                : SumAnimation

            setFirstBlock(welcomeJson.firstBlock)


            welcomeJson.news.news = welcomeJson.news.news.slice(0, 2)
            setBlogs(welcomeJson.news)
            const processedBlocks = []

            welcomeJson.blocks.blocks.forEach((block) => {
                block.path = welcome.featuresLink + block.alias + welcomeJson.blocks.query
                processedBlocks.push(block)
            })
            welcomeJson.blocks.blocks = processedBlocks
            setBlocks(welcomeJson.blocks)

            setLastBlock(welcomeJson.lastBlock)

        })()

    }, [localStorage.getItem('SELECTED_LANGUAGE')])


    useEffect(() => {
        document.title = defaultTitle
    }, [defaultTitle])

    return (
        <section>
            {welcomeBlock && (
                <Welcome
                    logo={welcomeBlock.logo}
                    title={welcomeBlock.title}
                    tagline={welcomeBlock.tagline}
                    text={welcomeBlock.text}
                    apple={welcomeBlock.apple}
                    android={welcomeBlock.android}
                    image={welcomeBlock.image}
                />
            )}

            {firstBlock && (
                    <BigBlock
                        header={firstBlock.header}
                        title={firstBlock.title}
                        leed={firstBlock.leed}
                        text={firstBlock.text}
                        animation={''}
                        animationPath={firstBlock.welcomeAnimation}
                        path={content.featuresLink + firstBlock.alias + firstBlock.query}
                    />
            )}

            {blogs &&
                <HomePageBlogs blogs={blogs}/>
            }

            {blocks && (
                    <Blocks blocks={blocks.blocks} header={blocks.header}/>
            )}

            {lastBlock && (
                    <LastBlock
                        title={lastBlock.title}
                        text={lastBlock.text}
                        logo={lastBlock.logo}
                    />
            )}
        </section>
    )
}

export default Home
