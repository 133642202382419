import React from "react";
import Language from "../core/Language";


const FontSelector = ({children}) => {
    const language = new Language();
    const LANG = language.language;
    const FontDefault = React.lazy(() => import('./FontDefault'));
    const FontRU = React.lazy(() => import('./FontRU'));

    return (<>
        <React.Suspense fallback={<></>}>
            { LANG === 'ru' ? <FontRU /> : <FontDefault /> }
        </React.Suspense>
        {children}
    </>);
}

export default FontSelector;