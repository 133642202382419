import {Routes, Route} from "react-router-dom"
import Host from "../core/Host.js"

/* Pages */
import Home from "../pages/Home"
import Features from '../pages/Features'
import FAQ from '../pages/FAQ'
import News from '../pages/News'
import NewsArticle from '../pages/NewsArticle'
import Beta from '../pages/Beta'
import About from '../pages/About'
import ErrorPage from "../pages/ErrorPage"

export default function Root() {
    const host = new Host()


    return (
        <Routes>
            {/* Home */}
            <Route
                index
                element = {<Home />}
                errorElement = {<ErrorPage />}
                key = "root0"
            />

            {/* Feature List */}
            <Route
                path={host.features}
                element={<Features/>}
                errorElement={<ErrorPage/>}
                key="root1"
            />

            {/* Feature Article */}
            <Route
                path={host.features + '/:alias'}
                element={<Features/>}
                errorElement={<ErrorPage/>}
                key="root2"
            />

            {/* FAQ */}
            <Route
                path={host.faq}
                element={<FAQ/>}
                errorElement={<ErrorPage/>}
                key="root3"
            />

            {/* Blog List */}
            <Route
                path={host.news}
                element={<News/>}
                errorElement={<ErrorPage/>}
                key="root4"
            />

            {/* Blog Article */}
            <Route
                path={host.news + '/:alias'}
                element={<NewsArticle/>}
                errorElement={<ErrorPage/>}
                key="root5"
            />

            {/* Beta Test Form */}
            <Route
                path={host.beta}
                element={<Beta/>}
                errorElement={<ErrorPage/>}
                key="root6"
            />

            {/* About */}
            <Route
                path={host.about}
                element={<About/>}
                errorElement={<ErrorPage/>}
                key="root7"
            />
        </Routes>
    )
}