import Host from "./Host.js";
import Language from "./Language.js";
import configData from "../config.json";

class Content {
  static #CMS = new Host();
  static #language = new Language();

  get defaultTitle() {
    return configData.defaultTitle;
  }

  get isMobile() {
    return window.matchMedia("(max-width: 640px)").matches;
  }

  get isTablet() {
    return window.matchMedia("(min-width: 641px) and (max-width: 1200px)")
      .matches;
  }

  get language() {
    return Content.#language.language;
  }

  set language(lang) {
    Content.#language.language = lang;
  }

  get defaultFeature() {
    return configData.defaultFeature;
  }

  get featuresLink() {
    return Content.#CMS.features;
  }

  get newsLink() {
    return Content.#CMS.news;
  }

  async getWelcome() {
    const path = Content.#CMS.getWelcome;
    const data = await Content.#CMS.getJSON(path);
    const query = this.getLanguageQuery();
    data.android.url = data.android.url + query.replace(query[0], "&");
    data.apple.url = data.apple.url + query.replace(query[0], "&");
    return data;
  }

  async getFirstBlock() {
    const path = Content.#CMS.getFirstBlock;
    const data = await Content.#CMS.getJSON(path);
    data.query = this.getLanguageQuery();
    return data;
  }

  async getBlocks() {
    const path = Content.#CMS.getBlocks;
    const data = await Content.#CMS.getJSON(path);
    data.query = this.getLanguageQuery();
    return data;
  }

  async getLastBlock() {
    const path = Content.#CMS.getLastBlock;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getFeatures() {
    const features = await this.getBlocks();
    const first = await this.getFirstBlock();
    const blocks = [...[first], ...features.blocks];
    blocks.forEach((block) => {
      block.query = this.getLanguageQuery();
    });
    return blocks;
  }

  async getFeature(alias = this.defaultFeature) {
    const path = Content.#CMS.getFeature + "/" + alias;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getFooter() {
    const path = Content.#CMS.getFooter;
    const data = await Content.#CMS.getJSON(path);
    data.socials.forEach((link) => {
      if (link.url.startsWith("/")) {
        link.url = link.url + this.getLanguageQuery();
      }
    });
    return data;
  }

  async getAdditionalBlock() {
    const path = Content.#CMS.getAdditionalBlock();
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getNavigation() {
    const path = Content.#CMS.getNavigation;
    const data = await Content.#CMS.getJSON(path);
    data.forEach((link) => {
      link.url = link.url + this.getLanguageQuery();
    });
    return data;
  }

  async getLanguages() {
    // const path = Content.#CMS.getLanguages;
    // const data = await Content.#CMS.getJSON(path, false).then((response) => {
    //   return response.filter((item) =>
    //     configData.languages.includes(item.short)
    //   );
    // });
    return [
      {
        "short": "ar",
        "name": "Arabic"
      },
      {
        "short": "en",
        "name": "English"
      },
      {
        "short": "fr",
        "name": "French"
      },
      {
        "short": "he",
        "name": "Hebrew"
      },
      {
        "short": "ru",
        "name": "Russian"
      },
      {
        "short": "es",
        "name": "Spanish"
      }
    ];
  }

  async getNews() {
    const path = Content.#CMS.getNews;
    let data = await Content.#CMS.getJSON(path);
    data.news.forEach((block) => {
      block.query = this.getLanguageQuery();
    });
    data.path = Content.#CMS.news;
    data.query = this.getLanguageQuery();
    return data;
  }

  async getNewsArticle(alias) {
    if (alias === undefined || alias === null) return false;
    const path = Content.#CMS.getNewsArticle + "/" + alias;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getFaqArticle() {
    const path = Content.#CMS.getFaqArticle;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getQuestions() {
    const path = Content.#CMS.getQuestions;
    const questions = await Content.#CMS.getJSON(path);
    const tids = [
      ...new Set(
        questions.map(function (obj) {
          return obj.tid;
        })
      ),
    ];

    const categories = [];
    for (let i = 0; i < tids.length; i++) {
      var category = questions.find(function (obj) {
        return obj.tid === tids[i];
      });
      const categoryQuestions = questions.filter(function (question) {
        return question.tid === tids[i];
      });

      categories.push({
        tid: category.tid,
        name: category.category,
        questions: categoryQuestions,
      });
    }

    return categories;
  }

  async getBeta() {
    const path = Content.#CMS.getBeta;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getJoinUs() {
    const path = Content.#CMS.getJoinUs;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getAbout() {
    const path = Content.#CMS.getAbout;
    const data = await Content.#CMS.getJSON(path);
    return data;
  }

  async getMuseum() {
    return await Content.#CMS.getJSON(Content.#CMS.getMuseum);
  }

  breakLine(text) {
    if (text === undefined || text === null) return "";
    text = text.replace(/\\n/g, "<br />");
    text = text.replace(/\n/g, "<br />");
    return text;
  }

  removeBreakLine(text) {
    if (text === undefined || text === null) return "";
    text = text.replace(/\\n/g, " ");
    text = text.replace(/\n/g, " ");
    return text;
  }

  boldMDtoHTML(text) {
    if (text === undefined || text === null) return "";
    const re = /\\\\|\\\*|\*\*((?:\\\\|\\\*\*|\*(?!\*)|[\S\s])*?)\*\*/g;
    text = text.replace(re, function (match, p1) {
      return match.startsWith("\\") ? match : "<b>" + p1 + "</b>";
    });
    return text;
  }

  removeMD(text) {
    if (text === undefined || text === null) return "";
    return text.replace(/\*\*/g, "");
  }

  splitRows(cells) {
    var odd = true;
    var row = [];
    var output = [];
    cells.forEach((cell) => {
      if (odd) {
        row = [];
        row.push(cell);
      } else {
        row.push(cell);
        output.push(row);
      }
      odd = !odd;
    });
    if (row.length === 1) {
      row.push(false);
      output.push(row);
    }
    return output;
  }

  async getLottie(path) {
    const response = await fetch(path);
    const data = await response.json();
    return data;
  }

  async getToken(path) {
    const data = await Content.#CMS.getJSON(path, false);
    return data;
  }

  getExtension(url) {
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    return extension.toLowerCase();
  }

  getLanguageQuery() {
    const lang = Content.#language.language;
    if (lang === Content.#language.defaultLanguage) return "";
    return "?l=" + lang;
  }
}

export default Content;
