import React, { useState, useEffect, useRef, useMemo } from 'react';
import lottie from "lottie-web";
import Spinner from './Spinner';
import './Animation.css';
import logoAnimation from "../files/features/Kaller_logo.json"

const Animation = (props) => {
    const [placeholder, setPlaceholder] = useState("");
    const containerRef = useRef(null);
    const animationRef = useRef(null);
    const key = props.animation.replace(/[^a-zA-Z0-9]/g, '');
    const render = props.render ? props.render : 'canvas';

    const options = useMemo(() => {
            if (props.json) {
                return {
                    renderer: render,
                    loop: true,
                    autoplay: true,
                    animationData: props.animationPath,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid meet'
                    }
                }
            }
            return {
                renderer: render,
                loop: true,
                autoplay: true,
                path: props.animation,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid meet'
                }
            }
    }, [render, props.animation, props.animationPath]);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (!animationRef.current) return;
            if (entry.isIntersecting) {
                animationRef.current.play();
            } else {
                animationRef.current.stop();
            }
        }, {
            rootMargin: '-120px 0px -60px 0px'
        });

        const animation = lottie.loadAnimation({
            ...options,
            container: containerRef.current,
        });
        animationRef.current = animation;
        animation.addEventListener('data_ready', () => {
            setPlaceholder('-hidden');
        });
        observer.observe(containerRef.current);

        return () => {
            if (animationRef.current) {
                animationRef.current.destroy();
            }
            observer.disconnect();
        };

    }, [options]);

    return <div>
        <div className={`animation-placeholder ${placeholder}`}>
            {!props.json &&
            <Spinner color='gray' />
            }
        </div>

        {<div
            ref={containerRef}
            key={key}
            className='animation-container'
        />}
    </div>
}
export default Animation