import React, { useState } from "react";
import Gallery from "./Gallery";
import galleryIcon from '../assets/gallery/gallery.svg';
import './Banner.css';

const Banner = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOverlay = () => {
        setIsOpen(!isOpen);
    };
    const gallery = props.gallery && props.gallery.length > 0
        ? props.gallery
        : false;

    return (
        <div className="banner-container"
            onClick={toggleOverlay}
        >
            {gallery && <img src={galleryIcon}
                alt="Gallery"
                className="banner-icon"
            />}
            <img src={props.banner}
                alt={props.alt}
                className={`banner-img ${gallery ? "banner-active" : ""}`}
            />
            {gallery && <Gallery
                isOpen={isOpen}
                onClose={toggleOverlay}
                gallery={gallery}
            />}
        </div>
    );
}

export default Banner;