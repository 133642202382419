import React, {useEffect, useState} from 'react'
import '../css/index.css'

const FontDirection = ({children}) => {
    const [direction, setDirection] = useState('ltr')

    useEffect(() => {
        const location = new URLSearchParams(window.location.href.split('?')[1]).get('l')

        let lang = location ? location : 'en'
        localStorage.setItem('SELECTED_LANGUAGE', lang)
        if (lang === 'ar' || lang === 'he') {
            setDirection('rtl')
        } else {
            setDirection('ltr')
        }

    }, [window.location.href])

    return (
        <bdo dir={direction}>
            <div style={{textAlign: direction === 'rtl' ? 'right' : 'left'}}
                 className={direction === 'rtl' ? 'borderRight' : 'borderLeft'}>
                {children}
            </div>
        </bdo>
    )
}

export default FontDirection