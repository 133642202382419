import React, {useEffect, useState} from 'react'
import devicePoster from "../assets/welcome/device-poster.webp"
import deviceMov from "../assets/welcome/device.mov"
import deviceWebm from "../assets/welcome/device.webm"
import freecompressdevice from "../assets/welcome/freecompressDevice.mp4"

const WelcomeVideo = () => {
    // const [isVideoUploaded, setVideoUploaded] = useState(false);


    // useEffect(() => {
    //     const video = document.querySelector("#welcome-simulator");
    //         video.onloadeddata = function() {
    //             setVideoUploaded(true);
    //         }
    //         const simulatorObserver = new IntersectionObserver(([entry]) => {
    //             if (!isVideoUploaded) return;
    //             if (entry.isIntersecting) {
    //                 video.play();
    //             } else {
    //                 video.pause();
    //                 video.currentTime = 0;
    //             }
    //         }, {
    //             rootMargin: '-40px 0px -40px 0px'
    //         });
    //         simulatorObserver.observe(video);

    //         return () => simulatorObserver.disconnect();

    // }, [isVideoUploaded]);
    // function  supportsHEVCAlpha () {
    //     const navigator = window.navigator;
    //     const ua = navigator.userAgent.toLowerCase()
    //     const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo)
    //     const isSafari = ((ua.indexOf('safari') !== -1) && (!(ua.indexOf('chrome')!== -1) && (ua.indexOf('version/')!== -1)))
    //     return isSafari && hasMediaCapabilities
    // }
    return (
        // <div dangerouslySetInnerHTML={{ __html:
        //         `<video
        //           loop
        //           muted="true"
        //           autoplay
        //           playsinline
        //           id="welcome-simulator"
        //           title="Kaller user interface"
        //           poster="${devicePoster}"
        //         >
        //             <source src="${supportsHEVCAlpha() ? deviceMov : deviceWebm} " type="video/mp4"/>
        //         </video>`
        // }}/>
        <video width="600" controls>
            <source src={freecompressdevice} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}

export default WelcomeVideo