import React from "react";
import Row from "../layout/Row";
import Block from "./Block";
import "./Blocks.css";

const Blocks = (props) => {
  const block = props.blocks;
  const header = props.header;


  return (
    <section className="blocks-container">
      {header && <h2 className="-center">{header}</h2>}
      {block && (
        <>
          <Row className="blocks-first-row">
            {block[0] && (
              <Block
                title={block[0].title}
                subtitle={block[0].leed}
                img={block[0].animation}
                path={block[0].path}
                soon={block[0].soon}
                render="svg"
              />
            )}
            {block[1] && (
              <Block
                title={block[1].title}
                subtitle={block[1].leed}
                img={block[1].animation}
                path={block[1].path}
                soon={block[1].soon}
              />
            )}
          </Row>
          <Row>
            {block[2] && (
              <Block
                title={block[2].title}
                subtitle={block[2].leed}
                img={block[2].animation}
                path={block[2].path}
                soon={block[2].soon}
              />
            )}
            {block[3] && (
              <Block
                title={block[3].title}
                subtitle={block[3].leed}
                img={block[3].animation}
                path={block[3].path}
                soon={block[3].soon}
              />
            )}
          </Row>
          <Row>
            {block[4] && (
              <Block
                title={block[4].title}
                subtitle={block[4].leed}
                img={block[4].animation}
                path={block[4].path}
                soon={block[4].soon}
              />
            )}
            {block[5] && (
              <Block
                title={block[5].title}
                subtitle={block[5].leed}
                img={block[5].animation}
                path={block[5].path}
                soon={block[5].soon}
              />
            )}
          </Row>
          <Row>
            {block[6] && (
              <Block
                title={block[6].title}
                subtitle={block[6].leed}
                img={block[6].animation}
                path={block[6].path}
                soon={block[6].soon}
              />
            )}
            {block[7] && (
              <Block
                title={block[7].title}
                subtitle={block[7].leed}
                img={block[7].animation}
                path={block[7].path}
                soon={block[7].soon}
              />
            )}
          </Row>
          <Row>
            {block[8] && (
              <Block
                title={block[8].title}
                subtitle={block[8].leed}
                img={block[8].animation}
                path={block[8].path}
                soon={block[8].soon}
              />
            )}
            {block[9] && (
              <Block
                title={block[9].title}
                subtitle={block[9].leed}
                img={block[9].animation}
                path={block[9].path}
                hash={block[9].hash}
                nid={block[9].id}
                soon={block[9].soon}
              />
            )}
          </Row>
        </>
      )}
    </section>
  );
};

export default Blocks;
