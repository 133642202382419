import React, {useLayoutEffect, useState, useEffect, useRef} from "react"
import Animation from './Animation'
import Content from '../core/Content'
import './Article.css'
import './Toon.css'
import './Block.css'

const Toon = (props) => {
    const content = new Content()
    const isMobile = content.isMobile || content.isTablet
    const firstImgRef = useRef()
    const [position, setPosition] = useState({})
    const [imgContainerHeight, setImgContainerHeight] = useState("100px")
    const [imgContainerFullHeight, setImgContainerFullHeight] = useState(false)
    const [visibility, setVisibility] = useState({display: 'block'})
    const toons = props.gallery
    const scrollBottomRef = useRef(null)
    const [floating, setFloating] = useState(false)
    const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false)


    const onScroll = () => {
        if (scrollBottomRef.current) {
            if ((document.querySelector('article').getBoundingClientRect().bottom - 640 > 0) && imgContainerFullHeight) {
                setIsScrollBtnVisible(true)
            } else {
                setIsScrollBtnVisible(false)
            }
        }
    }

    useEffect(() => {
        if (scrollBottomRef.current) {
            window.addEventListener("scroll", onScroll)
            // Clean-up
            return () => {
                window.removeEventListener("scroll", onScroll)
            }
        }
    }, [imgContainerFullHeight])


    useEffect(() => {
        const intervalId = setInterval(() => {
            setFloating((prevFloating) => !prevFloating)
        }, 1500)

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        if (props.parent === undefined) return
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isVisible = entry.isIntersecting
                var cssDisplay = {display: 'block'}
                if (!isVisible) {
                    cssDisplay = {display: 'none'}
                }
                setVisibility(cssDisplay)
            }, {threshold: 0, rootMargin: '-300px'}
        )
        if (props.parent.current) {
            observer.observe(props.parent.current)
        }
        return () => observer.disconnect()
    }, [props.parent])

    useLayoutEffect(() => {
        function updatePosition() {

            const blockContent = new Content()
            var style = {}
            const visibility = (blockContent.isMobile || blockContent.isTablet)
                ? 'none'
                : 'block'
            if (props.parent !== undefined) {
                const article = props.parent.current
                const articleBox = article.getBoundingClientRect()

                if (localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he') {
                    style = {
                        right: articleBox.left - 180 + 'px',
                        display: visibility
                    }
                } else {
                    style = {
                        left: articleBox.left - 180 + 'px',
                        display: visibility,
                    }
                }
            }
            setPosition(style)
        }

        window.addEventListener('resize', updatePosition)
        updatePosition()
        return () => window.removeEventListener('resize', updatePosition)
    }, [props.parent, props.parent.current.getBoundingClientRect().left])





    useEffect(() => {
        setImgContainerHeight(`${firstImgRef.current.offsetHeight * 0.648 + 40}px`)

        const handleResize = () => {
            setImgContainerHeight(`${firstImgRef.current.offsetHeight * 0.648 + 40}px`)
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('load', handleResize)

        return () => {
            if (!imgContainerFullHeight) {
                window.removeEventListener('resize', handleResize)
                window.removeEventListener('load', handleResize)
            }
        }
    }, [toons])

    const animationContainer = props.sticker ? "article-sticker" : "block-inner"

    return (
        <section className="article toon">
            {props.animation && <div
                className="block-img article-animation"
                style={position}
            >
                <div className="block-dummy"/>
                <div className={animationContainer}
                     style={visibility}
                >
                    {!isMobile && <Animation
                        animation={props.animation}
                        //need to fix "local_presence.json" 
                        //and "welcome.json"
                        //to enable canvas only render
                        render="svg"
                    />}
                </div>
            </div>}
            <div className="img_container_height" style={{height: !imgContainerFullHeight && imgContainerHeight, webkitMask: 'linear-gradient(180deg,#0000,#000 0% 99%,#0000)'}}>

                {toons && toons.map((toon, index) =>
                    <img
                        key={'toon' + index}
                        src={toon}
                        alt={props.title}
                        ref={index === 0 ? firstImgRef : null}
                        onLoad={() => setImgContainerHeight(`${firstImgRef.current.offsetHeight * 0.648 + 40}px`)}
                    />)
                }


                {/* <div className={`img_container_open ${floating ? 'float-up' : 'float-down'}`} onClick={(() => {setImgContainerFullHeight(!imgContainerFullHeight)})}>
                    <svg className={`svg_arrow ${!imgContainerFullHeight && "svg_arrow_close"}`} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Iconly/Bold/Arrow---Up-Circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Arrow---Up-Circle" transform="translate(2.000000, 2.000000)" fill="var(--blue)" fill-rule="nonzero">
                                <path d="M10,-2.84217094e-13 C15.52,-2.84217094e-13 20,4.49 20,10 L20,10 L19.9961558,10.2797649 C19.8478368,15.6705774 15.4264407,20 10,20 C4.49,20 -1.13074514e-13,15.52 -1.13074514e-13,10 C-1.13074514e-13,4.49 4.49,-2.84217094e-13 10,-2.84217094e-13 Z M10,7.21 C9.8,7.21 9.61,7.29 9.47,7.43 L9.47,7.43 L6,10.92 C5.85,11.06 5.78,11.25 5.78,11.44 C5.78,11.64 5.85,11.83 6,11.98 C6.3,12.27 6.77,12.27 7.06,11.97 L7.06,11.97 L10,9.02 L12.94,11.97 C13.23,12.27 13.71,12.27 14,11.98 C14.3,11.68 14.3,11.21 14,10.92 L14,10.92 L10.53,7.43 C10.39,7.29 10.2,7.21 10,7.21 Z"></path>
                            </g>
                        </g>
                    </svg>
                </div> */}


            </div>
            <div ref={scrollBottomRef} className="scrollBottomRef">

            </div>
            {
                imgContainerFullHeight ?
                    null
                    :
                    <div className="container_open_more" onClick={(() => {
                        setImgContainerFullHeight(!imgContainerFullHeight)
                        setIsScrollBtnVisible(!imgContainerFullHeight)
                    })}>
                        <span>Read More</span>
                    </div>
            }
            {/* {
                <div className={'scrollBtnContainer'} style={isScrollBtnVisible ? {transform: 'translateY(0)'} : {transform: 'translateY(100px)'}}>
                    <button onClick={() => {
                        scrollBottomRef.current?.scrollIntoView({behavior: 'instant'})

                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 -4.5 20 20"
                             version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)"
                                   fill="#fff">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path
                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                            id="arrow_down-[#338]">

                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            } */}
        </section>
    )
}

export default Toon