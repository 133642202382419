import React, { useState, useEffect } from "react";
import Content from "../core/Content";
import RowWithTwoNews from "../components/RowWithTwoNews";

const News = () => {
  const [header, setHeader] = useState();
  const [news, setNews] = useState();
  const pageContent = new Content();
  const [title, setTitle] = useState(pageContent.defaultTitle);

  useEffect(() => {
    const content = new Content();
    (async () => {
      let news = [];
      const response = await content.getNews();
      setTitle(response.header);
      setHeader(response.header);
      response.news.forEach((item) => {
        item.path = content.newsLink + item.alias;
        news.push(item);
      });
      news = content.splitRows(news);
      setNews(news);
    })();
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <section className="-main-column">
      {header && <h1>{header}</h1>}
      {news &&
        news.map((row, index) => (
          <RowWithTwoNews cells={row} key={"news-row-" + index} />
        ))}
    </section>
  );
};

export default News;
