import React, {useEffect} from "react";
import { HashLink } from 'react-router-hash-link';
import './Questions.css';
import FaqCategory from "./FaqCategory";

const Questions = (props) => {
    const categories = props.categories;
    
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'instant' });
    }
    
    useEffect(() => {
        let hash = window.location.hash;
        if (!hash) return;
        hash = hash.replace('#', '');
        const el = document.getElementById(hash);
        if (!el) return;
        scrollWithOffset(el);
    }, []);

    return (categories && <div className="-narrow-column">
        <section className="questions-categories">
            {categories.map((category, index) => {
                return (<div key={`cat${index}`} className="questions-category">
                    <HashLink
                        to={`#category${category.tid}`}
                        scroll={el => scrollWithOffset(el)}
                    >
                        <h4>{category.name}</h4>
                    </HashLink>
                    {category.questions.map((question) => {
                        return (
                            <p key={`question${question.id}`}
                                className="questions-question"
                            >
                                <HashLink to={`#question${question.id}`}
                                    className="questions-question-link"
                                    scroll={el => scrollWithOffset(el)}
                                >
                                    {question.title}
                                </HashLink>
                            </p>
                        );
                    })}
                </div>);
            })}
        </section>
        <section className="questions-categories">
            {categories.map((category) => {
                return (<FaqCategory
                    key={"faqCategory" + category.tid}
                    category={category}
                />);
            })}
        </section>
    </div>);
}

export default Questions;