import React from 'react'
import { Link } from "react-router-dom";
import Content from '../core/Content';
import Cell from '../layout/Cell';
import './Block.css';
import './BlogBlock.css';
import Window_to_the_Big_World_main from '../files/features/Window_to_the_Big_World_main.jpg'
import World_of_Onliners_main_1 from '../files/features/World_of_Onliners_main_1.jpg'

const NewsBlock = (props) => {
    const blockContent = new Content();

    return (
        <Cell className="blog-block-cell -half">
            <div className="news-block-img">
                <div className="news-block-dummy" />
                <div className="block-inner">
                    <Link to = {props.path}>
                        {props.banner.includes("Window_to_the_Big_World_main") ? 
                            <img src={Window_to_the_Big_World_main} alt=""/>
                            :
                            props.banner.includes("World_of_Onliners_main_1") ? 
                            <img src={World_of_Onliners_main_1} alt=""/>
                            :
                            <img src={props.banner} alt=""/>
                        }
                    </Link>
                </div>
            </div>
            <h3 className='blog-block-title'>
                <Link to = {props.path}
                    onClick = {() => {
                        window.scrollTo({top: 0, left: 0});
                    }} 
                    dangerouslySetInnerHTML = {{
                        __html: blockContent.removeBreakLine(props.title)
                    }}
                />
            </h3>
            { props.subtitle && <p className = 'news-block-subtitle'
                dangerouslySetInnerHTML = {{
                    __html: blockContent.boldMDtoHTML(props.subtitle)
                }} 
            /> }
            { props.date && <div className="news-block-date">{props.date}</div> }
        </Cell>
    );
}

export default NewsBlock;