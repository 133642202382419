import React from 'react';
import './JoinUsBlock.css';

const JoinUsBlock = (props) => {
    return (
        <div
            className="join-us-container"
            dangerouslySetInnerHTML={{ __html: props.body }}
        />
    );
}

export default JoinUsBlock;