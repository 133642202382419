import Language from "./Language";
import configData from "../config.json";

class Host {

    static #language = new Language();

    //Features page root
    get features() {
        return configData.root.features;
    }

    //Blog page root
    get news() {
        return configData.root.news;
    }

    //FAQ page root
    get faq() {
        return configData.root.faq;
    }

    //Beta request page root
    get beta() {
        return configData.root.beta;
    }

    //About page root
    get about() {
        return configData.root.about;
    }

    //First screen on the Home page
    get getWelcome() {
        return configData.get.welcome;
    }

    //Two columns block after "welcome" block
    get getFirstBlock() {
        return configData.get.firstBlock;
    }

    //Blocks with features on the Home page
    get getBlocks() {
        return configData.get.featureBlocks;
    }

    //Get feature page content
    get getFeature() {
        return configData.get.feature;
    }

    //Get footer content
    get getFooter() {
        return configData.get.footer;
    }

    //Get navigation content
    get getNavigation() {
        return configData.get.navigation;
    }

    //Get production language list
    get getLanguages() {
        return configData.get.languages;
    }

    //Get Bottom block on the Home page content
    get getLastBlock() {
        return configData.get.lastBlock;
    }

    //Get news list
    get getNews() {
        return configData.get.news;
    }

    //Get News article page content
    get getNewsArticle() {
        return configData.get.newsArticle;
    }

    //Get FAQ intro content
    get getFaqArticle() {
        return configData.get.faqIntro;
    }

    //Get FAQ content
    get getQuestions() {
        return configData.get.faq;
    }

    //Get Beta testing Request content
    get getBeta() {
        return configData.get.betaRequest;
    }

    //Get Join Us block content
    get getJoinUs() {
        return configData.get.joinUs;
    }

    //Get About content
    get getAbout() {
        return configData.get.page + '/' + configData.aboutNode;
    }

    //Get Museum content
    get getMuseum() {
        return configData.get.museum;
    }

    get domain() {
        return window.location.hostname === 'localhost'
            ? configData.cmsDevUrl
            : configData.cmsUrl;
    }

    async getJSON(path, language = true) {
        path = this.domain + path;
        if (language) path = path + "/" + Host.#language.language;
        const response = await fetch(path);
        const parsed = await response.json();
        return parsed;
    }
}

export default Host