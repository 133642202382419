import React from 'react';
import { Link } from "react-router-dom";
import Animation from './Animation';
import Content from '../core/Content';
import Row from '../layout/Row';
import Cell from '../layout/Cell';
import './Block.css';
import './BigBlock.css';

const BigBlock = (props) => {
    const About = new Content();
    const title = About.breakLine(props.title);

    return (
        <Row className="block-big-container">
            <Cell className="-whole -center">
                <h2 className='block-title'>
                    <Link to={props.path}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </h2>
                <div className="block-big-img">
                    <div className="block-big-dummy" />
                    <div className="block-inner big-animation">
                        <Animation
                            animationPath={props.animationPath}
                            animation={props.animation} 
                            render='svg'
                            json={true}
                        />
                    </div>
                </div>
            </Cell>
        </Row>
    );
}

export default BigBlock;