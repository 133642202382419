import React, { useState, useEffect, useRef } from 'react';
import Animation from './Animation';
import { Link } from "react-router-dom";
import Content from '../core/Content';
import Cell from '../layout/Cell';
import './Block.css';
import soon from '../assets/soon.png';

const Block = (props) => {
    const content = new Content();
    const label = (props.soon === "1") ? true : false;
    const [animation, setAnimation] = useState(false);
    const blockRef = useRef(null);
    const render = props.render ? props.render : 'canvas';

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setAnimation(props.img);
                observer.disconnect();
            }
        }, {
            rootMargin: '0px 0px 200px 0px'
        });
        observer.observe(blockRef.current);
        return () => observer.disconnect();
    }, [props.img]);

    return (
        <Cell className="-half -center">
            <h2 className="block-header">
                <Link to={props.path}
                    dangerouslySetInnerHTML={{
                        __html: content.breakLine(props.title)
                    }} 
                />
            </h2>
            <p className='block-subtitle'
                dangerouslySetInnerHTML={{
                    __html: content.boldMDtoHTML(props.subtitle)
                }} 
            />
            <div className="block-img" ref={blockRef}>
                {label && <img src={soon} alt="Coming Soon" className="block-soon" />}
                <div className="block-dummy"></div>
                <div className = "block-inner">
                    {animation && <Animation 
                        animation={animation}
                        render={render} 
                    />}
                </div>
            </div>
        </Cell>
    );
}

export default Block;