import React, { useLayoutEffect, useState, useEffect } from "react";
import Animation from './Animation';
import Content from '../core/Content';
import Banner from '../components/Banner';
import './Article.css';
import './Block.css';

const Article = (props) => {
    const content = new Content();
    const isMobile = content.isMobile || content.isTablet;
    const [position, setPosition] = useState({});
    const [visibility, setVisibility] = useState({ display: 'block' });
    const banner = props.banner ? props.banner : false;
    const title = content.removeBreakLine(props.title);

    useEffect(() => {
        if (props.parent === undefined) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isVisible = entry.isIntersecting;
                var cssDisplay = { display: 'block' };
                if (!isVisible) {
                    cssDisplay = { display: 'none' };
                }
                setVisibility(cssDisplay);
            }, { threshold: 0, rootMargin: '-300px' }
        );
        if (props.parent.current) {
            observer.observe(props.parent.current);
        }
        return () => observer.disconnect();
    }, [props.parent]);

    useLayoutEffect(() => {
        function updatePosition() {
            const blockContent = new Content();
            var style = {};
            const visibility = (blockContent.isMobile || blockContent.isTablet)
                ? 'none'
                : 'block';
            if (props.parent !== undefined) {
                const article = props.parent.current;
                const articleBox = article.getBoundingClientRect();
                if (localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he') {
                    style = {
                        right: articleBox.left - 180 + 'px',
                        display: visibility
                    };
                } else {
                    style = {
                        left: articleBox.left - 180 + 'px',
                        display: visibility
                    };
                }

            }
            setPosition(style);
        }

        window.addEventListener('resize', updatePosition);
        updatePosition();
        setTimeout(() => {
            updatePosition()
        }, 100)
        return () => window.removeEventListener('resize', updatePosition);
    }, [props.parent, props.parent.current.offsetWidth]);

    const animationContainer = props.sticker ? "article-sticker" : "block-inner";


    return (
        <section className="article">
            {props.animation && <div
                className={`block-img article-animation`}
                style={position}
            >
                <div className="block-dummy"/>
                <div className={animationContainer}
                     style={visibility}
                >
                    {!isMobile && <Animation
                        animation={props.animation}
                        //need to fix "local_presence.json" 
                        //and "welcome.json"
                        //to enable canvas only render
                        render="svg"
                    />}
                </div>
            </div>}
            <h1>{title}</h1>
            {props.leed && <p className="leed article-leed">
                {content.removeMD(props.leed)}
            </p>}
            {banner && <Banner
                banner={banner}
                gallery={props.gallery}
                alt={title}
                key={title}
            />}
            <div className="article-body"
                 dangerouslySetInnerHTML={{__html: props.body}}

            />
            {props.date && <div className="article-date">
                {props.date}
            </div>}
            {props.author && <div className="article-author">
                {props.author}
            </div>}
        </section>
    )
}

export default Article;