import React from 'react';
import {Link, useLocation} from "react-router-dom"
import Content from '../core/Content';
import Cell from '../layout/Cell';
import './Block.css';
import './NewsBlock.css';
import bannerPlug from '../assets/welcome.png';

const NewsBlock = (props) => {
    const content = new Content();
    const banner = props.banner ? props.banner : bannerPlug;
    const location = useLocation();

    return (
        <>
            {
                !location.pathname.includes(props.path) ?
                    <Cell className="news-block-cell -half">
                        <div className="news-block-img">
                            <div className="news-block-dummy" />
                            <div className="block-inner">
                                <Link to={props.path}
                                      onClick={() => {
                                          window.scrollTo({ top: 0, left: 0 });
                                      }}
                                >
                                    <img src={banner} alt={props.title} loading="lazy" />
                                </Link>
                            </div>
                        </div>
                        <h2 className={`news-block-title`}>
                            <Link to={props.path}
                                  onClick={() => {
                                      window.scrollTo({ top: 0, left: 0 });
                                  }}
                                  dangerouslySetInnerHTML={{
                                      __html: content.removeBreakLine(props.title)
                                  }}


                            />
                        </h2>
                        {props.subtitle && <p className='news-block-subtitle'
                                              dangerouslySetInnerHTML={{
                                                  __html: content.boldMDtoHTML(props.subtitle)
                                              }}
                        />}
                        {props.date && <div className="news-block-date">
                            {props.date}
                        </div>}
                    </Cell>
                    : null
            }
        </>

    );
}

export default NewsBlock;