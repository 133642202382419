import React from "react";
import Row from '../layout/Row';
import NewsBlock from './NewsBlock';

const RowWithTwoNews = (props) => {
    return (
        <Row>
            {props.cells[0] && <NewsBlock
                banner = {props.cells[0].banner ? props.cells[0].banner : false} 
                title = {props.cells[0].title} 
                subtitle = {props.cells[0].leed} 
                path = {props.cells[0].path + props.cells[0].query}
                key = {'cell' + props.cells[0].id}
                date = {props.cells[0].date}
            />}
            {props.cells[1] && <NewsBlock
                banner = {props.cells[1].banner ? props.cells[1].banner : false}  
                title = {props.cells[1].title} 
                subtitle = {props.cells[1].leed} 
                path = {props.cells[1].path + props.cells[1].query}
                key = {'cell' + props.cells[1].id}
                date = {props.cells[1].date}
            />}
        </Row>
    );
}

export default RowWithTwoNews;