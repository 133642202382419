import React, {useLayoutEffect, useState, useEffect} from 'react'
import Content from '../core/Content'
import AdditionalBlock from './AdditionalBlock'
import './AdditionalFeed.css'

const AdditionalFeed = (props) => {
    var [position, setPosition] = useState({})
    const [visibility, setVisibility] = useState({display: 'block'})

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isVisible = entry.isIntersecting
                var cssDisplay = {display: 'block'}
                if (!isVisible && entry !== undefined) {
                    cssDisplay = {display: 'none'}
                }
                setVisibility(cssDisplay)
            }, {threshold: 0, rootMargin: '-300px'}
        )
        observer.observe(props.parent.current)
        return () => {
            observer.disconnect()
        }
    }, [props.parent])

    useLayoutEffect(() => {
        function updatePosition() {
            const blockContent = new Content()
            var style = {}
            const visibility = (blockContent.isMobile || blockContent.isTablet)
                ? 'none'
                : 'block'
            if (props.parent.current) {
                const article = props.parent.current
                const articleBox = article.getBoundingClientRect()
                if (localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he') {
                    style = {
                        left: articleBox.left - 150 + 'px',
                        display: visibility
                    }
                } else {
                    style = {
                        right: articleBox.left - 170 + 'px',
                        display: visibility
                    }
                }

            }
            setPosition(style)
        }

        window.addEventListener('resize', updatePosition)
        updatePosition()
        return () => window.removeEventListener('resize', updatePosition)
    }, [props])

    return (
        <div className="additional-block-feed" style={position}>
            <div style={visibility}>
                {props.content && <AdditionalBlock content={props.content}/>}
            </div>
        </div>
    )
}

export default AdditionalFeed