import configData from "../config.json";

class Language {
    static #language;

    constructor() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let language = urlParams.get('l');
        language = language ? language.substring(0, 2) : null;
        Language.#language = localStorage.getItem('SELECTED_LANGUAGE')
            || configData.defaultLanguage;
    }

    get language() {
        return Language.#language;
    }

    set language(lang) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        lang = lang ? lang.substring(0, 2) : configData.defaultLanguage;
        if (lang === configData.defaultLanguage) {
            urlParams.delete('l');
        } else {
            urlParams.set('l', lang);
        }
        Language.#language = lang;
        localStorage.setItem('SELECTED_LANGUAGE', lang);
        window.location.search = urlParams;
    }

    get defaultLanguage() {
        return configData.defaultLanguage;
    }
}

export default Language;