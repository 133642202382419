import React, { useEffect, useState } from "react";
import Content from "../core/Content";
import Article from "../components/Article";
import Questions from "../components/Questions";

const FAQ = () => {
  const [article, setArticle] = useState(false);
  const [categories, setCategories] = useState(false);
  const pageContent = new Content();
  const [title, setTitle] = useState(pageContent.defaultTitle);

  useEffect(() => {
    const content = new Content();
    (async () => {
      const response = await content.getFaqArticle();
      setTitle(response.title);
      setArticle(response);
    })();
    (async () => {
      const response = await content.getQuestions();
      setCategories(response);
    })();
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <article>
        {article && (
          <Article
            title={article.title}
            body={article.body}
            banner={false}
            z={1}
          />
        )}
      </article>
      {categories && <Questions categories={categories} />}
    </>
  );
};

export default FAQ;
