import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './AdditionalBlock.css';

const AdditionalBlock = (props) => {
    const location = useLocation();
    const header = props.content.header;
    const link = props.content.path;
    const news = props.content.news;
    const query = props.content.query;
    
    return (
        <div className="additional-block-container">
            <div className="additional-block-wrapper">
               { header && <h3>
                   <Link to = {link + query}>
                       { header }
                   </Link>
               </h3>}
               { news && news.map((item, index) => {
                    return (
                    <div 
                        className = {`additional-block-news-container ${location.pathname.includes(item.alias) && "additional-block-news-container-active"}`} 
                        key = {'news' + index}
                    >
                        <div className='additional-block-data'><b>{item.date}</b></div>
                        <h4>
                            <Link to = {link + item.alias + query}
                                onClick = {() => {
                                    window.scrollTo({top: 0, left: 0});
                                }} 
                            >
                                <b>{ item.title }</b>
                            </Link>
                        </h4>
                    </div>
                    )
                })}
            </div>
        </div>
    );
}

export default AdditionalBlock;