import './Pager.css';

const Pager = (props) => {
    if (props.count === 1) return;
    var dots = [];
    for (var i = 0; i < props.count; i++) {
        dots.push(
            <div key={`dots ${i}`}
                className={`pager-dot ${i === props.active
                    ? "pager-active-dot" : "pager-inactive-dot"
                }`} 
            />
        );
    }

    return (
        <div className="pager-container">
            {dots}
        </div>
    );
}

export default Pager;