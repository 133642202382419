import React, { useEffect, useState } from 'react';
import './Spinner.css';

const Spinner = (props) => {
    const [color, setColor] = useState("spinner-white");

    useEffect(() => {
        if (props.color === 'gray') {
            setColor('spinner-gray');
        }
    }, [props.color]);

    return (
        <div className={`spinner ${color}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Spinner;